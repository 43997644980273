.preload * {
    transition: none !important;
}

* {
    box-sizing: border-box;
}

html, body, #main {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
}

body {
    overflow-x: hidden;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: rem(14);
    color: $color-text;
}

.logo {
    max-width: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        width: 100%;
    }
}
