$color-error: #ffd0ca;
$color-error-dark: #ae6872;
$color-warning: #fff9de;
$color-warning-dark: #e29800;;
$color-success: #eeffe1;
$color-success-dark: #75aa61;
$color-info: #ebf6ff;
$color-info-dark: #6181aa;

$color-main: #4D637B;
$secondary-color: #75b2f5;
$secondary-color-variant: #5094de;

$color-text: #333;
$color-gray-dark: #676767;
$color-gray: #d6d6d6;
$color-gray-medium: #e0e0e0;
$color-gray-light: #e6eaee;
$color-gray-lighter: #f2f2f2;

$text-lineheight: 1.25;

$margin-s: rem(10);
$margin-m: rem(20);
$margin-l: rem(30);
$margin-xl: rem(40);

$site-width: 1160px;
